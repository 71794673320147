import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VOverlay,{attrs:{"value":_vm.parcelLoading}},[_c(VProgressCircular,{attrs:{"color":"primary","indeterminate":"","size":"64"}})],1),_c('div',{staticClass:"map",attrs:{"id":"map"}},[_c('div',{staticClass:"mapboxgl-control-container"},[_c('div',{staticClass:"mapboxgl-ctrl-top-left"},[_c('div',[_c('div',{staticClass:"mapboxgl-ctrl mapboxgl-ctrl-group"},[_c(VMenu,{attrs:{"offset-x":"","close-on-content-click":false,"transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('button',_vm._g({staticClass:"mapboxgl-ctrl-fullscreen"},on),[_c(VIcon,{staticStyle:{"margin-top":"-3px"},attrs:{"size":"20","color":"black"}},[_vm._v("mdi-layers")])],1)]}}]),model:{value:(_vm.dialog.layer),callback:function ($$v) {_vm.$set(_vm.dialog, "layer", $$v)},expression:"dialog.layer"}},[_c('map-setting',{attrs:{"show":_vm.mapSettingFields,"linkedGroups":_vm.linkedGroupsLabels},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Layers "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog.layer = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)]},proxy:true}])})],1),_c(VMenu,{attrs:{"offset-x":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('button',_vm._g({staticClass:"mapboxgl-ctrl-fullscreen"},on),[_c(VIcon,{staticStyle:{"margin-top":"-3px"},attrs:{"size":"20","color":"black"}},[_vm._v("mdi-map")])],1)]}}])},[_c(VCard,[_c('div',{staticClass:"d-flex flex-wrap border-a"},_vm._l((_vm.mapStyles),function(style){return _c('div',{key:style.value,staticClass:"pa-2"},[_c('div',[_c('img',{staticClass:"hover-image map-style-thumbnail",class:_vm.mapStyle === style.style ? 'img-border' : '',attrs:{"src":("/map-layers/" + (style.value) + ".png")},on:{"click":function($event){_vm.mapStyle = style.style}}}),_c('div',{staticClass:"text-center thumbnail-text",staticStyle:{"margin-top":"-2px","font-size":"14px"}},[_vm._v(" "+_vm._s(style.text)+" ")])])])}),0)])],1)],1),(_vm.mapShowMore)?_c('div',{staticClass:"mapboxgl-ctrl mapboxgl-ctrl-group"},[_c('button',{staticClass:"mapboxgl-ctrl-fullscreen",on:{"click":function($event){_vm.geoscriptDialog = !_vm.geoscriptDialog}}},[_c(VIcon,{staticStyle:{"margin-top":"-3px"},attrs:{"size":"20","color":"black"}},[_vm._v(" mdi-segment ")])],1)]):_vm._e()])])])]),_c('MapInfo',{ref:"mapInfo",attrs:{"cursor":_vm.mapData.cursor,"value":_vm.mapInfo,"show-more":_vm.mapShowMore},on:{"update:cursor":function($event){return _vm.$set(_vm.mapData, "cursor", $event)},"update:showMore":function($event){_vm.mapShowMore=$event},"update:show-more":function($event){_vm.mapShowMore=$event}}}),_c('MapGeoscript',{attrs:{"visible-layers":_vm.geoscripts,"visible-geometries":_vm.styledGeometries,"show":_vm.geoscriptDialog},on:{"execute":_vm.executeGeoscript,"cancel":_vm.cancelGeoscript,"toggleGeometry":_vm.toggleGeometry},model:{value:(_vm.geoscriptDialog),callback:function ($$v) {_vm.geoscriptDialog=$$v},expression:"geoscriptDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
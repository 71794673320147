/* eslint-disable object-curly-newline */
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { v4 as uuid4 } from 'uuid';
import { randomNumber, setIntervalX, value } from '../utils';
import { geostyleParcelSearchHighlight } from '../utils/geostyles';
import { zoomFormatter, lngLatFormatter, isSatellite, applyGeostyleOnFeatures } from '../utils/map';
import config from '../config';

const DEFAULT_STYLE = 'mapbox://styles/mapbox/streets-v11';
const DEFAULT_MARKER_COLOR = 'red';

const OBJECT_TYPES = {
  MARKER: 'MARKER',
  GEOJSON: 'GEOJSON'
};

const DEFAULT_GEOSTYLE = {
  fillColor: '#6A6C6E',
  lineColor: '#6A6C6E',
  lineOpacity: 0.5,
  lineWidth: 2,
  circleRadius: 20,
  dash: 'default',
  dashLength: 0,
  gapLength: 0,
  dashArray: '[0, 0]',
  fillOpacity: 0.5,
  fillOpacityHover: 0,
  fillOpacitySatellite: 0.5,
  fillOpacitySatelliteHover: 0,

  lineJoin: 'miter',
  lineCap: 'butt',

  symbolPlacement: 'point',
  textColor: 'black',
  textFont: '["Open Sans Regular","Arial Unicode MS Regular"]',
  textOpacity: 1,
  textSize: 16,
  textOffset: '[0.0]',
  textAnchor: 'center',
  textJustify: 'center'
};

const LAYER_GEOSTYLE_KEY = {
  'circle-radius': 'circleRadius',
  'circle-color': 'fillColor',
  'circle-opacity': 'fillOpacity',
  'circle-stroke-color': 'lineColor',
  'circle-stroke-opacity': 'lineOpacity',
  'circle-stroke-width': 'lineWidth',

  'line-color': 'lineColor',
  'line-opacity': 'lineOpacity',
  'line-width': 'lineWidth',
  'line-dasharray': 'dasharray',
  'line-join': 'lineJoin',
  'line-cap': 'lineCap',

  'fill-color': 'fillColor',
  'fill-outline-color': 'lineColor',
  'fill-opacity': 'fillOpacity',
  'fill-opacity-hover': 'fillOpacityHover',
  'fill-opacity-satellite': 'fillOpacitySatellite',
  'fill-opacity-satellite-hover': 'fillOpacitySatelliteHover',

  'text-color': 'textColor',
  'text-font': 'textFont',
  'symbol-placement': 'symbolPlacement',
  'text-opacity': 'textOpacity',

  'text-size': 'textSize',
  'text-offset': 'textOffset',
  'text-anchor': 'textAnchor',
  'text-justify': 'textJustify'
};

class Map {
  /**
   *
   * @param {Map} mapbox
   *
   */
  constructor({
    accessToken,
    center,
    pitch,
    bearing,
    style,
    container,
    zoom,
    persistMapState,
    doubleClickZoom
  } = {}) {
    if (!accessToken) {
      console.warn('Access token is required');
      return;
      // accessToken = config.mapboxToken;
    }
    this.accessToken = accessToken;
    this.style = style || DEFAULT_STYLE;

    this.center = center || [0, 0];
    this.zoom = zoom || 12;
    this.pitch = pitch || 0;
    this.bearing = bearing || 0;
    this.doubleClickZoom = doubleClickZoom;

    this.mapInfo = {
      click: null,
      lngLat: null,
      pitch: null,
      bearing: null,
      zoom: null
    };

    this.container = container || 'map';
    this.sources = [];

    this.controls = {};
    this.persistMapState = !!persistMapState;
    this.persistSources = false;
    this.geostyle = DEFAULT_GEOSTYLE;
    this.styleLoaded = false;
    this.excludeLayers = [];

    // create map
    // this.loadSources();
    this.createMap();
    this.initControls();
  }

  loadSources() {
    try {
      const sources = JSON.parse(localStorage.getItem('mapSources')) || [];
      sources.forEach(source => {
        const s = this.createSource(source.data, source.type);
        this.sources.push(s);
      });
    } catch {
      return;
    }
  }

  setMap(map) {
    this.map = map;
  }

  createMap() {
    this.map = new mapboxgl.Map({
      accessToken: this.accessToken,
      container: this.container,
      style: this.style,
      center: this.center,
      zoom: this.zoom,
      pitch: this.pitch,
      bearing: this.bearing,
      projection: 'naturalEarth',
      doubleClickZoom: this.doubleClickZoom
    });

    this.map.once('style.load', () => {
      this.resize();
      this.persist();
      this.styleLoaded = true;
    });

    this.map.on('style.load', () => {
      this.reload();
    });

    this.map.loadImage('/map-marker.png', (error, image) => {
      if (error) throw error;
      this.map.addImage('custom-marker', image);
    });
  }

  getSources() {
    return this.sources;
  }

  initControls() {
    const controls = {
      ScaleControl: new mapboxgl.ScaleControl({ unit: 'imperial' }),
      GeolocateControl: new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true,
        showUserHeading: true
      }),
      NavigationControl: new mapboxgl.NavigationControl(),
      FullscreenControl: new mapboxgl.FullscreenControl()
    };
    this.controls = controls;
  }

  setStyle(style) {
    if (style === this.style) return;
    this.style = style;
    this.map.setStyle(style);
    localStorage.setItem('mapStyle', JSON.stringify(style));
  }

  /**
   *
   * @param {Array<mapboxgl.Control>} controls
   * @param {string} position
   *
   */

  addControls(controls, position) {
    const pos = position || 'top-right';
    controls.forEach(ctrl => {
      if (this.controls[ctrl]) {
        this.map.addControl(this.controls[ctrl], pos);
      }
    });
  }

  removeControls(controls) {
    controls.forEach(ctrl => {
      if (this.controls[ctrl]) {
        this.map.removeControl(this.controls[ctrl]);
      }
    });
  }

  persist() {
    this.map.on('moveend', () => {
      if (this.map && this.persistMapState) {
        const center = this.map.getCenter();
        const zoom = this.map.getZoom();
        const pitch = this.map.getPitch();
        const bearing = this.map.getBearing();
        localStorage.setItem('mapCenter', JSON.stringify(center));
        localStorage.setItem('mapZoom', JSON.stringify(zoom));
        localStorage.setItem('mapPitch', JSON.stringify(pitch));
        localStorage.setItem('mapBearing', JSON.stringify(bearing));
      }
    });
  }

  onUpdateMap(cb) {
    const emit = () => {
      cb(this.mapInfo);
    };

    this.mapInfo.pitch = Number(this.map.getPitch()).toFixed(2);
    this.mapInfo.bearing = Number(this.map.getBearing()).toFixed(2);
    this.mapInfo.zoom = zoomFormatter(this.map);
    emit();

    this.map.on('mousemove', e => {
      this.mapInfo.lngLat = lngLatFormatter(e);
      emit();
    });
    this.map.on('pitch', e => {
      this.mapInfo.pitch = Number(e.target.getPitch()).toFixed(2);
      emit();
    });

    this.map.on('rotate', e => {
      this.mapInfo.bearing = Number(e.target.getBearing()).toFixed(2);
      emit();
    });

    this.map.on('zoomend', e => {
      this.mapInfo.zoom = zoomFormatter(e.target);
      emit();
    });

    this.map.on('click', e => {
      this.mapInfo.click = lngLatFormatter(e);
      emit();
    });
  }

  onClick(cb) {
    this.map.on('click', e => {
      cb(lngLatFormatter(e));
    });
  }

  resize() {
    this.map.resize();
  }

  mapLayers(source, { type, symbolStyle } = {}) {
    const fill = {
      id: `${source}-fill`,
      type: 'fill',
      source,
      paint: {
        'fill-color': ['get', 'fill-color'],
        'fill-outline-color': ['get', 'fill-outline-color'],
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          isSatellite(this.style)
            ? ['get', 'fill-opacity-satellite-hover']
            : ['get', 'fill-opacity-hover'],
          isSatellite(this.style) ? ['get', 'fill-opacity-satellite'] : ['get', 'fill-opacity']
        ]
      },
      filter: ['==', '$type', 'Polygon']
    };

    const outline = {
      id: `${source}-outline`,
      type: 'line',
      source,
      paint: {
        'line-color': ['get', 'line-color'],
        'line-opacity': ['get', 'line-opacity'],
        'line-width': ['get', 'line-width']
      },
      layout: {
        'line-join': ['get', 'line-join'],
        'line-cap': ['get', 'line-cap']
      },
      filter: ['all', ['==', '$type', 'Polygon']]
    };

    const line = {
      id: `${source}-line`,
      type: 'line',
      source,
      paint: {
        'line-color': ['get', 'line-color'],
        'line-width': ['get', 'line-width'],
        'line-opacity': ['get', 'line-opacity'],
        'line-dasharray': ['get', 'line-dasharray']
      },
      filter: ['all', ['==', '$type', 'LineString']]
    };

    const circle = {
      id: `${source}-circle`,
      type: 'circle',
      source,
      paint: {
        'circle-radius': ['get', 'circle-radius'],
        'circle-color': ['get', 'circle-color'],
        'circle-opacity': ['get', 'circle-opacity'],
        'circle-stroke-color': ['get', 'circle-stroke-color'],
        'circle-stroke-opacity': ['get', 'circle-stroke-opacity'],
        'circle-stroke-width': ['get', 'circle-stroke-width']
      },
      filter: ['any', ['==', '$type', 'Point']]
    };

    const symbol = {
      id: `${source}-symbol`,
      type: 'symbol',
      source,
      paint: {
        'text-color': ['get', 'text-color'],
        'text-opacity': ['get', 'text-opacity']
      },
      layout: {
        'symbol-placement': symbolStyle?.symbolPlacement || 'point',
        'text-field': ['get', 'text'],
        'text-size': ['get', 'text-size'],
        'text-font': symbolStyle?.textFont || ['Open Sans Regular', 'Arial Unicode MS Regular'],
        'text-offset': ['get', 'text-offset'],
        'text-anchor': ['get', 'text-anchor'],
        'text-justify': ['get', 'text-justify']
      }
    };

    if (type === 'marker') {
      symbol.layout = {
        'icon-image': 'custom-marker'
      };
      return [symbol];
    }

    return [fill, outline, circle, line, symbol];
  }

  /**
   *
   * @param {object} geostyle
   */
  static dashArray(geostyle) {
    let dasharray;
    if (geostyle?.dash === 'default') {
      dasharray = [geostyle?.dashLength || 0, geostyle?.gapLength || 0];
      if (dasharray[0] === 0 && dasharray[1] === 0) {
        dasharray = [];
      }
    } else if (geostyle?.dash === 'array') {
      try {
        const da = JSON.parse(geostyle?.dashArray);
        if (Array.isArray(da)) {
          dasharray = da;
        }
      } catch {
        //
      }
    }
    dasharray = dasharray || [];
    dasharray = dasharray.map(i => Number(i));
    return dasharray;
  }

  static validateGeostyle(geostyle) {
    const numberFields = [
      'fillOpacity',
      'lineOpacity',
      'lineWidth',
      'circleRadius',
      'fillOpacity',
      'fillOpacityHover',
      'fillOpacitySatellite',
      'fillOpacitySatelliteHover'
    ];

    const res = { ...DEFAULT_GEOSTYLE, ...(geostyle || {}) };

    if (!geostyle?.fillOpacitySatellite && geostyle?.fillOpacity) {
      res.fillOpacitySatellite = geostyle.fillOpacity;
    }

    res.dasharray = Map.dashArray(res);
    try {
      if (!Array.isArray(res.textOffset)) {
        res.textOffset = JSON.parse(res.textOffset);
        if (!Array.isArray(res.textOffset)) {
          throw new Error('Invalid text offset');
        }
      }
    } catch {
      res.textOffset = [0.0];
    }
    // try {
    //   res.textFont = JSON.parse(res.textFont);
    // } catch (error) {
    //   console.error(error);
    // }
    if (typeof res.textFont === 'string') {
      res.textFont = JSON.parse(res.textFont);
    }

    Object.keys(res).forEach(key => {
      if (numberFields.includes(key)) {
        res[key] = Number(res[key]);
      }
    });

    return res;
  }

  static geostyleToProperties(geostyle) {
    const gs = Map.validateGeostyle(geostyle);
    const res = {};
    Object.keys(LAYER_GEOSTYLE_KEY).forEach(key => {
      res[key] = gs[LAYER_GEOSTYLE_KEY[key]];
    });
    return res;
  }

  static applyGeostyle(geometry, geostyle) {
    const style = Map.validateGeostyle(geostyle);
    const properties = Map.geostyleToProperties(style);
    let res = geometry || {};

    if (res.type === 'Feature') {
      res = {
        type: 'FeatureCollection',
        features: [res]
      };
    }
    res.features = res.features.map(feature => ({
      ...feature,
      properties: { ...(feature.properties || {}), ...properties },
      id: randomNumber()
    }));
    return res;
  }

  addLayers(source, type) {
    const layers = this.mapLayers(source, { type });
    layers.forEach(layer => {
      if (this.map.getLayer(layer)) {
        this.map.removeLayer(layer);
      }
      this.map.addLayer(layer);
    });
  }

  findSources(key, val) {
    return this.sources.filter(source => {
      const searchValue = value(source, key);
      if (Array.isArray(searchValue)) {
        return searchValue.includes(val);
      }
      return searchValue === val;
    });
  }

  findSource(id) {
    const index = this.sources.findIndex(i => i.id === id);
    return {
      index,
      source: this.sources[index]
    };
  }

  setLayoutProperty(layerId, state = true) {
    this.map.setLayoutProperty(layerId, 'visibility', state ? 'visible' : 'none');
  }

  hideLayer(id) {
    const { source, index } = this.findSource(id);
    if (index === -1) {
      console.error(`Source ${id} not found`);
      return;
    }
    const layers = this.mapLayers(id, { type: source.data.type }).map(i => i.id);
    layers.forEach(lid => {
      this.setLayoutProperty(lid, false);
    });
    this.sources[index].visible = false;
  }

  showLayer(id) {
    const { source, index } = this.findSource(id);
    if (index === -1) {
      console.error(`Source ${id} not found`);
      return;
    }
    const layers = this.mapLayers(id, { type: source.data.type }).map(i => i.id);
    layers.forEach(lid => {
      this.setLayoutProperty(lid, true);
    });
    this.sources[index].visible = true;
  }

  addSource(id) {
    const { source, index } = this.findSource(id);
    if (index === -1) {
      console.error(`Source ${id} not found`);
      return;
    }
    if (source.type === OBJECT_TYPES.GEOJSON) {
      this.addGeoJSON(source.params);
    }
  }

  removeSource(id) {
    const { source, index } = this.findSource(id);

    if (index === -1 || !source) {
      console.error(`Source ${id} not found`);
      return;
    }

    const layers = this.mapLayers(id, { type: source?.params?.type }).map(i => i.id);

    layers.forEach(lid => {
      if (this.map.getLayer(lid)) {
        this.map.removeLayer(lid);
      }
    });

    if (this.map.getSource(id)) {
      this.map.removeSource(id);
    }
  }

  reload() {
    this.sources.forEach(s => {
      s.remove();
      s.add();
    });
  }

  enableHover(source) {
    const id = `${source}-fill`;
    let hoverId = null;
    this.map.on('mousemove', id, e => {
      this.map.getCanvas().style.cursor = 'pointer';
      if (e.features.length > 0) {
        if (hoverId !== null) {
          this.map.setFeatureState(
            {
              source: source,
              id: hoverId
            },
            {
              hover: false
            }
          );
        }
        hoverId = e.features[0].id;
        this.map.setFeatureState(
          {
            source: source,
            id: hoverId
          },
          {
            hover: true
          }
        );
      }
    });

    this.map.on('mouseleave', id, () => {
      this.map.getCanvas().style.cursor = '';
      if (hoverId !== null) {
        this.map.setFeatureState(
          {
            source: source,
            id: hoverId
          },
          {
            hover: false
          }
        );
      }
      hoverId = null;
    });
  }

  createSource(data, type, usedBy) {
    const layerUsedBy = [];

    if (usedBy) {
      if (Array.isArray(usedBy)) {
        layerUsedBy.push(...usedBy);
      } else if (typeof usedBy === 'string') {
        layerUsedBy.push(usedBy);
      }
    }

    if (data.geoscript) {
      if (Array.isArray) {
        layerUsedBy.push(...data.geoscript);
      } else {
        layerUsedBy.push(data.geoscript);
      }
    }

    let visible = false;
    if (data.visible === undefined || data.visible === true) {
      visible = true;
    }

    const id = data.id || uuid4();
    return {
      data: data,
      id,
      type,
      usedBy: layerUsedBy,
      visible,
      add: () => {
        if (type === OBJECT_TYPES.GEOJSON) {
          this.addGeoJSON(id, data);
        } else if (type === OBJECT_TYPES.MARKER) {
          this.addMarker(id, data);
        }
      },
      remove: () => {
        if (type === OBJECT_TYPES.GEOJSON) {
          this.removeGeoJSON(id, data);
        } else if (type === OBJECT_TYPES.MARKER) {
          this.removeMarker(id);
        }
      },
      hide: () => {
        if (type === OBJECT_TYPES.GEOJSON) {
          this.hideLayer(id);
        } else if (type === OBJECT_TYPES.MARKER) {
          this.removeMarker(id);
        }
      },
      show: () => {
        if (type === OBJECT_TYPES.GEOJSON) {
          this.showLayer(id);
        } else if (type === OBJECT_TYPES.MARKER) {
          this.addMarker(id, data);
        }
      },
      destroy: () => {
        this.destroySource(id);
      }
    };
  }

  addMarker(id, { center, rotation, scale, color } = {}) {
    const { index } = this.findSource(id);

    center = center || [0, 0];
    rotation = rotation || 0;
    scale = scale || 0;
    color = color || DEFAULT_MARKER_COLOR;

    const marker = new mapboxgl.Marker({
      color,
      rotation,
      scale
    })
      .setLngLat(center)
      .addTo(this.map);

    if (index !== -1) {
      this.sources[index].marker = marker;
      this.sources[index].visible = true;
    }
  }

  removeMarker(id) {
    const { source, index } = this.findSource(id);
    if (source && source.marker) {
      source.marker.remove();
      this.sources[index].visible = false;
    }
  }

  addGeoJSON(id, { geojson, hover, type, visible, geostyle } = {}) {
    const { source, index } = this.findSource(id);
    if (source.visible === false) {
      visible = false;
    } else {
      if (visible === undefined || source.visible) {
        visible = true;
      } else {
        visible = false;
      }
    }
    const data = geojson.type === 'geojson' ? geojson : { type: 'geojson', data: geojson };
    const layers = this.mapLayers(id, { type, symbolStyle: geostyle });
    this.map.addSource(id, data);
    layers.forEach(layer => {
      if (!layer.layout) {
        layer.layout = {};
      }
      layer.layout.visibility = visible ? 'visible' : 'none';
      if (!this.excludeLayers.includes(layer.type)) {
        this.map.addLayer(layer);
      }
    });
    if (hover) {
      this.enableHover(id);
    }
    this.sources[index].visible = visible;
  }

  removeGeoJSON(id, { type } = {}) {
    const { index } = this.findSource(id);
    const layers = this.mapLayers(id, { type });
    layers.forEach(layer => {
      if (this.map.getLayer(layer.id)) {
        this.map.removeLayer(layer.id);
      }
    });
    if (this.map.getSource(id)) {
      this.map.removeSource(id);
    }
  }

  /**
   *
   * @param {string} id
   * @param {*} data
   * @param {('GEOJSON'|'MARKER')} type
   */
  add(id, type, data) {
    if (!type) {
      throw new Error('Object type is required');
    }

    let usedBy = null;
    const { index } = this.findSource(id);

    if (index !== -1) {
      usedBy = this.sources[index].usedBy;
      this.sources[index].remove();
      this.sources.splice(index, 1);
    }

    const source = this.createSource({ ...data, id }, type, usedBy);
    this.sources.push(source);
    source.add();
    if (this.persistSources) {
      localStorage.setItem('mapSources', JSON.stringify(this.sources));
    }
    return source;
  }

  onLoad(cb) {
    this.map.once('load', () => {
      cb();
    });
  }

  destroySource(id) {
    const { source, index } = this.findSource(id);
    source.remove();
    this.sources.splice(index, 1);
  }

  destroy() {
    this.map = null;
  }

  highlight({ geojson, timeout } = {}) {
    if (typeof geojson !== 'object' || !['Feature', 'FeatureCollection'].includes(geojson.type)) {
      throw new Error('Invalid geojson');
    }
    if (!timeout) timeout = 3000;
    const id = 'search-highlight';
    const data = {
      geojson: Map.applyGeostyle(geojson, geostyleParcelSearchHighlight)
    };

    const listener = () => {
      this.map.off('moveend', listener);
      const source = this.add(id, OBJECT_TYPES.GEOJSON, data);
      setTimeout(() => {
        source.hide();
        source.destroy();
      }, 1000);
    };
    this.map.on('moveend', listener);
  }
}
export default Map;

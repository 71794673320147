export default {
  type: 'FeatureCollection',
  features: [
    {
      bbox: [-70.15427, 42.060158, -70.14655, 42.0771],
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [
          [-70.15427, 42.0771],
          [-70.15355, 42.07594],
          [-70.14655, 42.06524],
          [-70.14736, 42.06387],
          [-70.147415, 42.060158]
        ]
      },
      properties: {
        '@id': 'fdebefc0-d83f-11ec-a660-1761adceafd2',
        Team: 'N'
      }
    },
    {
      bbox: [-70.0776, 41.9578, -69.9971, 41.96676],
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [
          [-70.0776, 41.9578],
          [-70.03878, 41.96287],
          [-70.02797, 41.96401],
          [-70.02185, 41.96452],
          [-69.99856, 41.9666],
          [-69.9971, 41.96676]
        ]
      },
      properties: {
        '@id': 'fdebefc1-d83f-11ec-a660-1761adceafd2',
        Team: 'S'
      }
    }
  ]
};

import moo from 'moo';
/**
 *
 * @param {String} str
 */
const removeComments = str => {
  if (!str) {
    return '';
  }
  return str.replace(/\/\*[\s\S]*?\*\/|([^\\:]|^)\/\/.*$/gm, '');
};

/**
 *
 * @param {String} str
 * @returns
 */
const removeComma = str => {
  return str.replace(/,\s*$/, '');
};

/**
 *
 * @param {Object} query
 * @returns {Array<Object>}
 */
const parseLayers = query => {
  const keys = ['layers', 'circles'];
  const res = [];

  keys.forEach(i => {
    if (query[i]) {
      const qKeys = String(query[i] || '').split(',');
      qKeys.forEach(key => {
        const re = /\[(.*)\]/;
        if (re.exec(key)) {
          key = re.exec(key)[1];
        }
        const [layer, geostyle] = key.split('*');
        res.push({ key: (layer || '').trim(), style: (geostyle || '').trim() });
      });
    }
  });
  return res;
};

const parseGeoscript = str => {
  const lexer = moo.compile({
    variable: { match: /[a-zA-Z0-9-+.]*\s*=\s*[a-zA-Z0-9+-.]*/ },
    comment: /\/\/.*$/,
    key: /[a-zA-Z0-9-_.$]+/,
    style: {
      match: /(?:\*)[a-zA-Z0-9-_.$]*/,
      value: s => s.replace('*', '')
    },
    whitespace: / /,
    newline: { match: /\n/, lineBreaks: true }
    // variable: { match: /(?:[a-zA-Z0-9]+)\s*(?:=)\s*(?:[a-zA-Z0-9])/ },
  });
  lexer.reset(str);
  const res = [];
  while (true) {
    const data = lexer.next();
    if (data) {
      res.push(data);
    } else {
      break;
    }
  }
  return res;
};

/**
 *
 * @param {String} str geoscript
 * @returns {Array<{key:String, style:String}>}
 */
const geoscriptMapKeys = str => {
  if (!str) {
    return null;
  }
  const tokens = parseGeoscript(str);
  const data = {};
  let ki = -1;
  for (const token of tokens) {
    if (token.type === 'key') {
      ki++;
      if (!data[ki]) {
        data[ki] = {};
      }
      data[ki].key = token.value;
      data[ki].type = 'layer';
    } else if (token.type === 'style') {
      data[ki].style = token.value;
    } else if (token.type === 'variable') {
      ki++;
      const sp = token.value.split('=').map(i => i.trim());
      if (!data[ki]) {
        data[ki] = {};
      }
      data[ki].key = sp[0];
      data[ki].value = sp[1];
      data[ki].type = token.type;
    }
  }
  return Object.keys(data).map(i => {
    return {
      key: data[i].key || '',
      style: data[i].style || '',
      value: data[i].value || '',
      type: data[i].type || ''
    };
  });
};

const formatGeoscript = (str, metadata) => {
  if (!metadata) {
    metadata = {};
  }
  const tokens = parseGeoscript(str);
  let html = ``;
  tokens.forEach((token, idx) => {
    let tokenHTML = '';

    const notFound = metadata[token.value]?.notFound;
    const _repeated = metadata[token.value]?.repeated;
    const repeated =
      (_repeated && token.type === 'key') ||
      (_repeated && token.type === 'style' && metadata[tokens[idx - 1].value]?.repeated);

    if (notFound) {
      tokenHTML += `<span class="gs-not-found">`;
    }
    if (repeated) {
      tokenHTML += `<span class="gs-repeated">`;
    }

    if (token.type === 'newline') {
      tokenHTML += `<br/>`;
    } else if (token.type === 'key') {
      const kType = metadata ? metadata[token.value]?.type : '';
      let cls = 'gs-key';
      if (kType) {
        cls += `-${kType}`;
      }
      tokenHTML += `<span class="${cls}">${token.value}</span>`;
    } else if (token.type === 'whitespace') {
      tokenHTML += `<span class="gs-whitespace">&nbsp;</span>`;
    } else if (token.type === 'style' && token.value) {
      tokenHTML += `<span class="gs-style-separator">*</span><span class="gs-style">${token.value}</span>`;
    } else {
      tokenHTML += `<span class="gs-${token.type}">${token.text}</span>`;
    }
    if (notFound) {
      tokenHTML += `</span>`;
    }
    if (repeated) {
      tokenHTML += `</span>`;
    }
    html += tokenHTML;
  });
  return html;
};

/**
 *
 * @param {*} param0
 * @returns {Array<object>}
 */
const repeatedKeys = ({ geoscript, keys }) => {
  let sortedGs = geoscriptMapKeys(geoscript) || keys;
  sortedGs = sortedGs.sort((a, b) => {
    return a.key.localeCompare(b.key);
  });
  const results = [];
  for (let i = 0; i < sortedGs.length - 1; i++) {
    if (sortedGs[i + 1].key === sortedGs[i].key && sortedGs[i + 1].style === sortedGs[i].style) {
      results.push(sortedGs[i]);
    }
  }
  return results;
};

const parseCoordinate = (value, decimal = 6) => {
  return parseFloat(parseFloat(value).toFixed(decimal));
};

const parseVariable = (key, value) => {
  if (!key) {
    return;
  }
  value = value.trim();
  let result;
  switch (key) {
    case 'zoom':
      result = parseFloat(value);
      break;
    case 'center':
      result = value?.split(',')?.reverse();
      result = result?.map(i => parseCoordinate(i));
      break;
    case 'bearing':
      result = parseFloat(value);
      break;
    case 'pitch':
      result = parseFloat(value);
      break;
    case 'style':
      result = value;
      break;
    case 'org':
      result = value;
      break;
    default:
      result = null;
      break;
  }
  return result;
};

export {
  parseGeoscript,
  removeComments,
  parseLayers,
  formatGeoscript,
  geoscriptMapKeys,
  removeComma,
  repeatedKeys,
  parseVariable,
  parseCoordinate
};
